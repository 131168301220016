<template>
	<PageLayout title="External Link"></PageLayout>
</template>

<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

export default {
	name: 'DepositExternalLink',
	components: {
		PageLayout,
	},
	async mounted() {
		await this.getUrlAndRedirect();
	},
	methods: {
		async getUrlAndRedirect() {
			const url = await apiClient.getExternalDepositUrl();
      window.location.href = url;
		},
	},
};
</script>
